import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MobileInfoPanelBottomComponent } from './mobile-info-panel-bottom/mobile-info-panel-bottom.component';
import { SharedModule } from '../../shared/shared.module';
import { MiniGalleryComponent } from './mini-gallery/mini-gallery.component';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { HoursComponent } from './hours/hours.component';
import { ScoreCardComponent } from './score-card/score-card.component';
import { ScoreCardDialogComponent } from './score-card/dialog/score-card-dialog.component';
import { ContactComponent } from './contact/contact.component';
import { InfoPanelComponent } from './info-panel.component';
import { PricingComponent, RatingComponent, ResourcesListComponent } from '@volago/attraction';
import { TranslocoRootModule } from '@volago/core';


@NgModule({
  declarations: [
    MobileInfoPanelBottomComponent,
    InfoPanelComponent,
    MiniGalleryComponent,
    HoursComponent,
    ScoreCardComponent,
    ScoreCardDialogComponent,
    ContactComponent,
  ],
  imports: [
    PricingComponent,
    ResourcesListComponent,
    CommonModule,
    SharedModule,
    GalleryModule,
    RatingComponent,
    TranslocoRootModule,
    NgOptimizedImage
  ],
  exports: [
    MobileInfoPanelBottomComponent,
    InfoPanelComponent,
    MiniGalleryComponent,
  ]
})
export class InfoPanelModule { }

