import { Component, Input } from '@angular/core';
import { Hours } from '@volago/attraction';

@Component({
  selector: 'volago-hours',
  templateUrl: './hours.component.html',
})
export class HoursComponent {

  @Input() data: Hours;

}
