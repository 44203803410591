import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Attraction } from './attraction';
import { DataService } from '@volago/core';

@Injectable({
  providedIn: 'root',
})
export class AttractionDataService extends DataService<Attraction> {

  constructor(firestore: Firestore) {
    super(firestore, 'attractions');
  }

}
