<div class="p-2">

  <a *ngIf="data.officialUrl" class="absolute right-0 p-2 text-inherit" target="_blank" [href]="data.officialUrl">
    <span class="material-symbols-outlined"> open_in_new </span>
  </a>

  <div class="py-4">
    <div *ngIf="!data.open else hours">zawsze dostępne</div>
    <ng-template #hours>
      <div>{{data.open}}</div>
    </ng-template>

    <div *ngIf="data.estimatedVisitTime" class="py-2">
      Spodziewany czas wizyty <b>{{data.estimatedVisitTime}}</b>
    </div>

  </div> 

</div>